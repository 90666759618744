import React, { Component, useContext, useRef, forwardRef } from "react";
import { useTheme } from "../themes/themeContext";
import emailjs from '@emailjs/browser';
import {HeaderContact} from "./Header";
import FooterModern from "./FooterModern";

var classNames = require('classnames');



//const Contact = forwardRef(({ scroll }, ref) => (
export default function Contact({...props}){
  const { theme } = useTheme()
        
    
  let currentTheme = classNames({
    'bg-light text-dark': theme === 'light',
    'bg-green text-light': theme === 'green',
    'bg-blue text-light': theme === 'blue',
    'bg-dark text-light': theme === 'dark'
  })

  return(
    <div
    ref={props.ref}
    onScroll={(e) => {
        props.scroll = e.target.scrollTop / (e.target.scrollHeight - window.innerHeight)
    }}
    className="scroll"
    id="containerElement"
    >        
      <HeaderContact/>        
      <ContactForm theme={currentTheme}/>
      <FooterModern/>
    </div>
  )
}  


function ContactForm({...props}) {
    
    const form = useRef();

  
    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm(
          'service_ro67o1e', 
          'template_4rw938h', 
          form.current, 
          'user_AtPhwhYS2kg8hkTvGl9KZ')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        form.current.reset();
    };
  
    return (  
        <section
        className={classNames((props.theme))}
        >
        <form 
        ref={form} 
        onSubmit={sendEmail}>
            <div 
            id='contact'
            className={classNames((props.theme),"h-screen px-5 py-24 realtive mx-auto")}
            >
            <div className="flex flex-col text-center w-full mb-12">
                <br/>
                <h1 className={classNames((props.theme),"sm:text-5xl text-2xl font-medium title-font mb-4 text-white100")}>
                Get In Contact
                </h1>
                <p className={classNames((props.theme),"lg:w-2/3 mx-auto text-white100 leading-relaxed text-base")}>
                I would be happy to advise you on my services without obligation.
                </p>
            </div>
            
            <div className="flex justify-center">
                <div className="flex flex-wrap m-2 ">
                    <NameElement theme={props.theme}/>
                    <EmailElement theme={props.theme}/>
                    <MessageElement theme={props.theme}/>
                    <SendElement theme={props.theme}/>
                </div>
            </div>        
            </div>
        </form>
        </section>
    );
}


function NameElement({...props}) {
  
  return (  
    <div className="p-2 w-1/2">
        <div className="relative">
        <label 
        className={classNames((props.theme),"leading-7 text-sm text-white100")}>
            Name
        </label>
        <input
            name="user_name"
            id="user_name"
            className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-gray-500 text-base outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
        />
        </div>
    </div>
  );
}


function EmailElement({...props}) {
  
    return (  
    <div className="p-2 w-1/2">
        <div className="relative">
          <label
            className={classNames((props.theme),"leading-7 text-sm text-white100 bg-opacity-50")}
          >
            Email
          </label>
          <input
            id="user_email"
            name="user_email" 
            className="w-full bg-gray-100 bg-opacity-50 rounded border 
            border-gray-300 focus:border-gray-500 text-base outline-none 
            text-dark py-1 px-3 leading-8 transition-colors 
            duration-200 ease-in-out"
          />
        </div>
    </div>
    );
}


function MessageElement({...props}) {
  
    return (  
        <div className="p-2 w-full">
        <div className="relative">
          <label
            //for="message"
            className={classNames((props.theme),"leading-7 text-sm text-white100")}
          >
            Message
          </label>
          <textarea
            id="message"
            name="message"
            className="w-full bg-gray-100 bg-opacity-50 rounded border 
            border-gray-300 focus:border-gray-500 h-32 text-base outline-none 
            text-dark py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
          ></textarea>
        </div>
      </div>
    );
}


function SendElement({...props}) {
  
    return (  
        <div className="p-2 w-full">
            <button 
            type="submit" value="Send"
            className="flex mx-auto text-white100 bg-gray-500 border-0 py-2 px-8 
            focus:outline-none hover:bg-gray-600 rounded text-lg">
            Send
            </button>
        </div>
    );
}

// export default Contact