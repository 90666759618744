import React, { Component, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTheme } from "../themes/themeContext";
//import { Menu, Transition, Popover, Switch, Disclosure } from '@headlessui/react'

var classNames = require('classnames');



export default function TransitionSection(){
    const { theme } = useTheme()
    
    let currentTheme = classNames({
        'bg-dark text-white100': theme === 'light',
        'bg-green text-light': theme === 'green',
        'bg-blue text-light': theme === 'blue',
        'bg-dark text-light': theme === 'dark'
    })
        
    return (    
    // <div className="techs h-screen">
        <VisualTransition theme={currentTheme}/>
    // </div> 
    );
}



function VisualTransition({...props}) {
    const { theme } = useTheme()
    
    let currentTheme = classNames({
        'from-light text-white100': theme === 'light',
        'from-green text-light': theme === 'green',
        'from-blue text-light': theme === 'blue',
        'from-dark text-light': theme === 'dark'
    })
        
    return (  
    <div className={
        classNames((currentTheme),
        "bg-gradient-to-t w-full h-40")}>
    </div>
    );
}