import React, { Component, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useTheme } from "../themes/themeContext";
import { Menu, Transition, Popover, Switch, Disclosure } from '@headlessui/react'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid'



var classNames = require('classnames');

export default function Introduction(){
    const { theme } = useTheme()
    
    let currentTheme = classNames({
        'bg-dark100 text-white100': theme === 'light',
        'bg-green text-light': theme === 'green',
        'bg-blue text-light': theme === 'blue',
        'bg-dark text-light': theme === 'dark'
    })
        
    return (    
    <div className="introduction h-screen text-white100">
        <FlyoutMenu theme={currentTheme}/>
    </div> 
    );
}


const solutions = [
    {
      name: "Who am I?",
      description:
        "I'm a react-three-fiber developer liking that the 3D web democratizes 3D art and that it is creative and challenging. I studied visual computing in germany and graduated with excellence. After working for 1.5 years as a VR Unity developer I became a freelancer, working on diverse international projects related to three.js. In my projects I'm cooperating with freelance 3D artists.",
      href: false
    },
    {
      name: "What is the 3D web?",
      description:
        "The 3D web deploys 3D content onto websites. The model in the back of this website is 3D content. Whereas in gaming 3D content is popular, it is not yet common to see websites containing 3D content. The options this novel technology offers, allows to reinvent the web at it's core. Games can be deployed online, VR experiences can be accessible for everyone and the web can simply become more beautiful and involving. Get inspired:",
      href: true
    },
    {
      name: "Why using 3D content on the web?",
      description:
        "3D websites are involving and likely to increase the average time a user visits your website. The third dimension allows to utilize storytelling and gamification in novel ways to better communicate your content. Stand out with an innovative website.",
      href: false
    }
]


function FlyoutMenu({...props}) {
    // make accordions close when one is opened.
    const AccordionRefs = useRef([]) // store accordion buttons as Refs
    const [currentAccordion, setCurrentAccordion] = useState(0) // set the current


    function closeCurrent(e) {
      const button = e.target.closest("button") // get the button
      const buttonParent = button.parentElement.parentElement // get the buttons parent (<dt> tag here)
      const parent = buttonParent.parentElement // get the buttons parent parent (Disclosure as div here)
      const index = Array.prototype.indexOf.call(parent.children, buttonParent) // find the index of the button in container
      console.log("index", index)
      for (let i = 0; i < AccordionRefs.current.length; i++) {
        // loop throug
        if (
          AccordionRefs.current[i].querySelector("button") &&
          AccordionRefs.current[i]
            .querySelector("button")
            .getAttribute("aria-expanded") === "true" &&
          currentAccordion !== index // if it is opened and not the current
        ) {
          AccordionRefs.current[i].querySelector("button").click() // then trigger a click to close it.
        }
      }
      setCurrentAccordion(index) // and set the current
    }


    return (
      <div className="bg-bg-gray">
        <div className="p-4 mx-auto max-w-7xl sm:px-6 lg:px-12">
          <div className="divide-y-2 divide-gray-200 ">
            <dl className="mt-20 space-y-6 divide-y divide-gray-200 ">
              {solutions.map((solution, index) => {
                return (
                  <Disclosure as="div" className="pt-6">
                    {({ open }) => (
                      <>
                        <dt
                          onClick={closeCurrent}
                          ref={(el) => (AccordionRefs.current[index] = el)}
                        >
                          <Disclosure.Button className="flex justify-between w-full text-left text-2xl md:text-4xl focus:outline-none">
                            <span className="w-full font-medium text-gray-900">
                              {solution.name}
                            </span>
                            <span className="flex mt-4 ml-6 menuItems-center h-7">
                              <ChevronDownIcon 
                              className={`${
                                open ? 'rotate-180 transform' : ''
                                } h-5 w-5 md:h-10 md:w-10`} />
                            </span>
                          </Disclosure.Button>
                        </dt>
                        <Disclosure.Panel as="dd" className="mt-2">
                          <div className={classNames((props.theme),"w-full text-base rounded p-10")}>
                            {solution.description}
                            <br/>
                            <div className="p-2 justify-center">
                              {solution.href && <AwwwardsButton theme={props.theme}/>}
                            </div>
                          </div>
                          
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                )
              })}
            </dl>
          </div>
        </div>
      </div>
    )
}




function Advantages({...props}) {
    const { theme } = useTheme()
    
    let currentTheme = classNames({
        'from-dark text-white100': theme === 'light',
        'from-green text-light': theme === 'green',
        'from-blue text-light': theme === 'blue',
        'from-dark text-light': theme === 'dark'
    })
        
    return (  
    <div className="flex w-full h-screen justify-end">
        <div className={
            classNames((currentTheme),
            '')}>
            <img 
            class="transform -rotate-90"
            src={'/images/tech2_roughness.png'}/>

            {/* <p className={'text-left text-3xl'}>
            Increase  
            <br/>
            your Average Time
            <br/>
            on Site
            </p> */}
        </div>
    </div>
    );
}


function AwwwardsButton({...props}){
  const { theme } = useTheme()
    
  let currentTheme = classNames({
    'bg-dark200 text-light hover:bg-dark': theme === 'light',
    'bg-green200 text-light hover:bg-green100': theme === 'green',
    'bg-blue100 text-light hover:bg-blue200': theme === 'blue',
    'bg-dark200 text-light hover:bg-dark100': theme === 'dark'
  })

  return(
  <button 
      onClick={(e) => {
      e.preventDefault();
      window.location.href='https://www.awwwards.com/awwwards/collections/three-js/';
      }}
      className={classNames((currentTheme),"font-bold py-2 px-4 rounded-full")}
      >
        Visit Awwwards
      {/* <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
          >
          <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
          <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
      </svg> */}
  </button>
  )
}
// Show your products in 3D
//                  <br/>
//                  Gamification
//                  <br/>




// function MyComponent() {
//     const [isShowing, setIsShowing] = useState(false)
//     console.log(isShowing)

//     return (
//     <>
//     <button
//         className="text-white100 border-2 justify-item-left w-full h-50 p-1"
//         onClick={() => setIsShowing((isShowing) => !isShowing)}            
//         >
//             Who I am
//     </button>
//     {/* <div
//         className="flex justify-end w-full h-screen -ml-0"
//         > */}
//         <Transition
//             className={'grid grid-cols-5 bg-dark text-white100 w-full h-5'}
//             // show={isShowing}
//             // enter="transition ease-in-out duration-500 transform"
//             // enterFrom="translate-x-full"
//             // enterTo="-translate-x-0"
//             // leave="transition ease-in-out duration-500 transform"
//             // leaveFrom="-translate-x-0"
//             // leaveTo="translate-x-full"
//             show={isShowing}
//             enter="transition-opacity duration-500"
//             enterFrom="opacity-0"
//             enterTo="opacity-100"
//             leave="transition-opacity duration-500"
//             leaveFrom="opacity-100"
//             leaveTo="opacity-0"
//             >
//             <div
//                 visible={true}
//                 className="col-start-1 col-end-6 bg-dark text-white100 p-5"         
//                 >
//                 I will fade in and out
//                 I will fade in and out
//                 I will fade in and out
//                 I will fade in and out
//                 I will fade in and out
//                 I will fade in and out
//                 I will fade in and out
//                 I will fade in and out
//                 I will fade in and out
//                 I will fade in and out
//                 I will fade in and out
//                 I will fade in and out
//             </div>
//         </Transition>
//     {/* </div> */}
//     </>
//     )
// }


// function MyDisclosure() {
//     let open =[false, false, false]

//     return (
//       <div className="w-full pt-16">
//         <div className="mx-auto w-full rounded-2xl">
//           <OpeningPanel 
//             question={'Who am I?'}
//             content={'I am a 3D web developer'}
//             open={open}
//             index={0}
//             />
//           <OpeningPanel 
//             question={'What is the 3D web?'}
//             content={'I am a 3D web developer'}
//             open={open}
//             index={1}
//             />
//           <OpeningPanel 
//             question={'Why using 3D content on the web?'}
//             content={'I am a 3D web developer'}
//             open={open}
//             index={2}
//             />
//         </div>
//       </div>
//     )
// }


// function OpeningPanel({...props}){
//     console.log(props.open[props.index])

//     return(        
//         <Disclosure 
//         as="div" 
//         >
//         {({open}) => (
//           <>
//             <Disclosure.Button 
//             className="flex w-full justify-between bg-dark bg-opacity-50 px-6 pt-5 pb-5 py-1 text-xl md:bg-opacity-0 md:px-32 md:pt-10 md:pb-10 md:py-2 md:text-3xl text-left"
//             >
//               <span>{props.question}</span>
//               <ChevronUpIcon
//                 className={`${
//                   open ? 'rotate-180 transform' : ''
//                 } h-5 w-5 md:h-10 md:w-10`}
//               />
//             </Disclosure.Button>
//             <Disclosure.Panel 
//             className="pt-4 pb-4 px-10 md:px-32 bg-dark text-sm"
//             >
//               {props.content}
//             </Disclosure.Panel>
//           </>
//         )}
//       </Disclosure>
//     )
// }