import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { useTheme } from "../themes/themeContext";
var classNames = require('classnames');

export default function Technologies(){
    const { theme } = useTheme()
    
    let currentTheme = classNames({
        'bg-light text-dark': theme === 'light',
        'bg-green text-light': theme === 'green',
        'bg-blue text-light': theme === 'blue',
        'bg-dark text-light': theme === 'dark'
    })
        
    return (    
    <div className="techs">
        <TechStack theme={currentTheme}/>
    </div> 
    );
}


function TechStack({...props}) {
    return (  
        <div className={classNames((props.theme),
            'p-1 w-full h-2/4 px-5 pt-20 pb-20')}>
            <div className="grid grid-cols-3 pt-10 gap-4 justify-items-center">
                <img 
                    className={"h-20 w-20 md:h-32 md:w-32"}
                    src="./images/react-logo.png" 
                    alt="react"/>
                <img 
                    className={"h-20 w-20 md:h-32 md:w-32"}
                    src="./images/three-js-logo.png" 
                    alt="threejs"/>
                <img 
                    className={"h-16 w-25 md:h-32 md:w-45"}
                    src="./images/amazon-web-services - Kopie.png" 
                    alt="aws"/>
            </div>
        </div>
    );
}