import React, { Component, forwardRef } from "react";
import { 
    SSAO,
    Glitch,
    GodRays,
    Noise,
    Bloom, 
    Outline,
    SelectiveBloom,     
    Vignette, 
    Sepia,
    DotScreen,
    Pixelation,
    HueSaturation,
    BrightnessContrast,
    ToneMapping,
    Scanline,
    ChromaticAberration,
    ColorAverage,
    Grid,
    SMAA,
    DepthOfField,
    bokehScale
} from "@react-three/postprocessing"
import { 
    Resizer,
    KernelSize,
    BlendFunction
} from 'postprocessing'
  

const BrightnessContrastEffect = (props) => {
    if(props.visible || props.visible === undefined){
        return(
            <BrightnessContrast
            brightness={0.5} // brightness. min: -1, max: 1
            contrast={0} // contrast: min -1, max: 1
            />
        )
    }
    else{
        return(
          null
        )
    };
};


const BokehScaleEffect = (props) => {
    if(props.visible || props.visible === undefined){
        return(
            <bokehScale
            // brightness={0.5} // brightness. min: -1, max: 1
            // contrast={0} // contrast: min -1, max: 1
            />
        )
    }
    else{
        return(
          null
        )
    };
};

const HueSaturationEffect = (props) => {
    if(props.visible || props.visible === undefined){
        return(
            <HueSaturation
                blendFunction={BlendFunction.NORMAL} // blend mode
                hue={1} // hue in radians
                saturation={1} // saturation in radians
            />
        )
    }
    else{
        return(
          null
        )
    };
};

const PixelationEffect = (props) => {
    if(props.visible || props.visible === undefined){
        return(
            <Pixelation
            granularity={5} // pixel granularity
            />
        )
    }
    else{
        return(
          null
        )
    };
};


const DotScreenEffect = (props) => {
    if(props.visible || props.visible === undefined){
        return(
            <DotScreen
                blendFunction={BlendFunction.NORMAL} // blend mode
                angle={Math.PI * 0.5} // angle of the dot pattern
                scale={1} // scale of the dot pattern
            />
        )
    }
    else{
        return(
          null
        )
    };
};

const SepiaEffect = (props) => {
    if(props.visible || props.visible === undefined){
        return(
            <Sepia
            intensity={1} // sepia intensity
            blendFunction={BlendFunction.NORMAL} // blend mode
            />
        )
    }
    else{
        return(
          null
        )
    };
};


const VignetteEffect = (props) => {
    if(props.visible || props.visible === undefined){
        return(
            <Vignette
                offset={0.05} // vignette offset
                darkness={1} // vignette darkness
                eskil={true} // Eskil's vignette technique
                blendFunction={BlendFunction.NORMAL} // blend mode
            /> 
        )
    }
    else{
        return(
          null
        )
    };
};


const SelectiveBloomEffect = (props) => {
    if(props.visible || props.visible === undefined){
        return(
            null
            // <SelectiveBloom
            //     lights={[lightRef1, lightRef2]} // ⚠️ REQUIRED! all relevant lights
            //     selection={[meshRef1, meshRef2]} // selection of objects that will have bloom effect
            //     selectionLayer={10} // selection layer
            //     intensity={1.0} // The bloom intensity.
            //     blurPass={undefined} // A blur pass.
            //     width={Resizer.AUTO_SIZE} // render width
            //     height={Resizer.AUTO_SIZE} // render height
            //     kernelSize={KernelSize.LARGE} // blur kernel size
            //     luminanceThreshold={0.9} // luminance threshold. Raise this value to mask out darker elements in the scene.
            //     luminanceSmoothing={0.025} // smoothness of the luminance threshold. Range is [0, 1]
            // /> 
        )
    }
    else{
        return(
          null
        )
    };
};


const OutlineEffect = (props) => {
    if(props.visible || props.visible === undefined){
        return(
            null
            // <Outline
            //     selection={[meshRef1, meshRef2]} // selection of objects that will be outlined
            //     selectionLayer={10} // selection layer
            //     blendFunction={BlendFunction.SCREEN} // set this to BlendFunction.ALPHA for dark outlines
            //     patternTexture={null} // a pattern texture
            //     edgeStrength={2.5} // the edge strength
            //     pulseSpeed={0.0} // a pulse speed. A value of zero disables the pulse effect
            //     visibleEdgeColor={0xffffff} // the color of visible edges
            //     hiddenEdgeColor={0x22090a} // the color of hidden edges
            //     width={Resizer.AUTO_SIZE} // render width
            //     height={Resizer.AUTO_SIZE} // render height
            //     kernelSize={KernelSize.LARGE} // blur kernel size
            //     blur={false} // whether the outline should be blurred
            //     xRay={true} // indicates whether X-Ray outlines are enabled
            // />
        )
    }
    else{
        return(
          null
        )
    };
};


const BloomEffect = (props) => {
    if(props.visible || props.visible === undefined){
        return(
            <Bloom
                intensity={3.0} // The bloom intensity.
                blurPass={undefined} // A blur pass.
                width={Resizer.AUTO_SIZE} // render width
                height={Resizer.AUTO_SIZE} // render height
                kernelSize={KernelSize.SMALL} // blur kernel size
                luminanceThreshold={0.7} // luminance threshold. Raise this value to mask out darker elements in the scene.
                luminanceSmoothing={0.025} // smoothness of the luminance threshold. Range is [0, 1]
            />
        )
    }
    else{
        return(
          null
        )
    };
};


const NoiseEffect = (props) => {

    if(props.visible || props.visible === undefined){
        return(
            <Noise
                premultiply={true}// enables or disables noise premultiplication
                blendFunction={BlendFunction.ADD} // blend mode
            />
        )
    }
    else{
        return(
          null
        )
    };
};


const ScanlineEffect = (props) => {

    if(props.visible || props.visible === undefined){
        return(
            <Scanline          
                blendFunction={BlendFunction.LIGHTEN} // blend mode
                density={20} // scanline density
            />
        )
    }
    else{
        return(
          null
        )
    };
};


const ToneMappingEffect = (props) => {

    if(props.visible || props.visible === undefined){
        return(
            <ToneMapping
                blendFunction={BlendFunction.NORMAL} // blend mode
                adaptive={true} // toggle adaptive luminance map usage
                resolution={256} // texture resolution of the luminance map
                middleGrey={0.6} // middle grey factor
                maxLuminance={4.0} // maximum luminance
                averageLuminance={0.5} // average luminance
                adaptationRate={0.5} // luminance adaptation rate
            />
        )
    }
    else{
        return(
          null
        )
    };
};


const ColorAverageEffect = (props) => {

    if(props.visible || props.visible === undefined){
        return(
            <ColorAverage          
                blendFunction={BlendFunction.AVERAGE} // blend mode
            /> 
        )
    }
    else{
        return(
          null
        )
    };
};


const GridEffect = (props) => {

    if(props.visible || props.visible === undefined){
        return(
            <Grid
                blendFunction={BlendFunction.OVERLAY} // blend mode
                scale={3} // grid pattern scale
                lineWidth={0.1} // grid pattern line width
                //size={{ 1, 1 }} // overrides the default pass width and height
            />
        )
    }
    else{
        return(
          null
        )
    };
};


const DepthOfFieldEffect = (props) => {

    if(props.visible || props.visible === undefined){
        return(
            <DepthOfField 
                focusDistance={30} 
                focalLength={0.002} 
                bokehScale={2} 
                height={1000} 
            />
        )
    }
    else{
        return(
          null
        )
    };
};


export {
    BrightnessContrastEffect, 
    HueSaturationEffect, 
    PixelationEffect,
    DotScreenEffect,
    SepiaEffect,
    VignetteEffect,
    SelectiveBloomEffect,
    OutlineEffect,
    BloomEffect,
    NoiseEffect,
    ScanlineEffect,
    ToneMappingEffect,
    ColorAverageEffect,
    GridEffect,
    DepthOfFieldEffect,
    BokehScaleEffect
};

