import * as THREE from 'three'
import { useRef, useEffect, useMemo, Suspense } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import { useGLTF, useAnimations, Html, useProgress } from '@react-three/drei'
import useRefs from 'react-use-refs'
import React from "react";
var classNames = require('classnames');

function Loader() {
  const { progress } = useProgress()
  return <Html center>{progress}</Html>
}

let plantIsVisible = false;
var xPercent = 0;
//let scrollModel = 0;
  
export default function Model({ scroll, ...props }) {
  
  const { width, height } = useThree((state) => state.viewport)
  const [group, keyLight, stripLight, fillLight] = useRefs()


  document.onmousemove = function(event) {
    xPercent = event.pageX / window.innerWidth;
   //yPercent = event.pageY / window.innerHeight;
  }
  
  useFrame((state, delta) => {
    //scroll.current = scroll.current * (1 / 0.45);
    group.current.rotation.y = (xPercent - 0.5) * 0.5;
    //group.current.rotation.y = THREE.MathUtils.damp(group.current.rotation.y, -0.5 + scroll.current, 4, delta)
    //group.current.position.x = THREE.MathUtils.damp(group.current.position.x, (-width / 7) * (1/2), 4, delta)
  })

  
  return (
    <>
      <perspectiveCamera 
        attachObject={['shadow', 'camera']} 
        />
      <group ref={group} position={[0, 0, 0]} {...props}>
        <Fragments 
          visible={true} 
          scroll={scroll} 
          scale={2} 
          theme={props.theme}/>
          <Suspense fallback={<Loader/>}>
            <Plant
              position={[2.8,-4.4,-3]}
              rotation={[
                Math.PI * 0.25,
                Math.PI * 0.3,
                Math.PI * -0.15]}
              visible={true} 
              scroll={scroll} 
              scale={8} 
              theme={props.theme}/>
          </Suspense>
          <Suspense fallback={<Loader/>}>   
          <Plant2
            position={[-2.5,-3.9,-4]}
            rotation={[0,Math.PI * 0.6,0]}
            visible={true} 
            scroll={scroll} 
            scale={8} 
            theme={props.theme}/>
          </Suspense>
        <Libelle
          position={[-1,-2.9,-0.5]}
          rotation={[Math.PI * 0.2 ,0,0]}
          visible={true} 
          scroll={scroll} 
          scale={2} 
          theme={props.theme}
          />
      </group>
    </>
  )
}
  
  

function Fragments({ scroll, visible, ...props }) {
  const group = useRef()
  const { scene, nodes, animations, materials } = useGLTF('/face_02_05glb.glb')
  const { actions } = useAnimations(animations, group)
  
  

  useMemo(() => Object.values(nodes).forEach(obj =>
    obj.isMesh && 
    Object.assign(obj, { 
      castShadow: true, 
      receiveShadow: true, 
      needsUpdate: true })
  ), [nodes])

  useEffect(() => {
    if (visible)
      Object.keys(actions).forEach((key) => {
        actions[key].play().paused = true
      })
      materials.Außen.metalness = 0.1; 
      materials.Außen.roughness = 0.5; 
      materials.Innen.metalness = 0.85;
  }, [visible])

  useFrame(({ camera }) => {
    Object.keys(actions).forEach((key) => {
      actions[key].time = 
      THREE.MathUtils.lerp(actions[key].time, 
      actions[key].getClip().duration * (scroll.current * (1 / 0.45)),
      0.05)  
    })

    if(props.theme === 'light'){
      materials.Außen.color.r = 0.1;
      materials.Außen.color.g = 0.1; 
      materials.Außen.color.b = 0.1;
    }
    if(props.theme === 'green'){
      materials.Außen.color.r = 0.05;
      materials.Außen.color.g = 0.08; 
      materials.Außen.color.b = 0.05;
    }
    if(props.theme === 'blue'){
      materials.Außen.color.r = 0.05;
      materials.Außen.color.g = 0.05; 
      materials.Außen.color.b = 0.1;
    }
    if(props.theme === 'dark'){
      materials.Außen.color.r = 0.1;
      materials.Außen.color.g = 0.05; 
      materials.Außen.color.b = 0.05;
    }
    
  })

  return( 
  <primitive 
  ref={group} 
  object={scene} 
  {...props} /> 
  )
}



function Plant({ scroll, visible, ...props }) {
  const group = useRef()
  const { scene, nodes, animations, materials } = useGLTF('Plant_09_05_1.glb')
  const { actions } = useAnimations(animations, group)
 
  
  // materials.Plant.metalness = 0.2;
  // materials.Plant.roughness = 0.9;
  //materials.Plant.blending = 0.95;

  useMemo(() => Object.values(nodes).forEach(obj =>
    obj.isMesh && 
    Object.assign(obj, { 
      castShadow: true, 
      receiveShadow: true, 
      needsUpdate: true })
  ), [nodes])

  useEffect(() => {
    if (visible)
      Object.keys(actions).forEach((key) => {
        actions[key].play().paused = true
      })
  }, [visible])

  useFrame(({ camera }) => {
    Object.keys(actions).forEach((key) => {
      actions[key].time = 
      THREE.MathUtils.lerp(actions[key].time, 
      actions[key].getClip().duration * (scroll.current * (1 / 0.7)), 0.05)  
    })
    
    if(props.theme === 'light'){
      materials.Plant.color.r = 0.4;
      materials.Plant.color.g = 0.4; 
      materials.Plant.color.b = 0.4;
      materials.Stem.color.r = 0.4;
      materials.Stem.color.g = 0.4; 
      materials.Stem.color.b = 0.4;
    }
    if(props.theme === 'green'){
      materials.Plant.color.r = 0.3;
      materials.Plant.color.g = 0.5; 
      materials.Plant.color.b = 0.3;
      materials.Stem.color.r = 0.3;
      materials.Stem.color.g = 0.5; 
      materials.Stem.color.b = 0.3;
    }
    if(props.theme === 'blue'){
      materials.Plant.color.r = 0.41;
      materials.Plant.color.g = 0.41; 
      materials.Plant.color.b = 0.41;
      materials.Stem.color.r = 0.41;
      materials.Stem.color.g = 0.41; 
      materials.Stem.color.b = 0.41;
    }
    if(props.theme === 'dark'){
      materials.Plant.color.r = 0.5;
      materials.Plant.color.g = 0.3; 
      materials.Plant.color.b = 0.3;
      materials.Stem.color.r = 0.5;
      materials.Stem.color.g = 0.3; 
      materials.Stem.color.b = 0.3;
    }
  })

  return( 
  <primitive 
  ref={group} 
  object={scene} 
  {...props} /> 
  )
}


function Plant2({ scroll, visible, ...props }) {
  const group = useRef()
  const { scene, nodes, animations, materials } = useGLTF('Plant_09_05_2.glb')
  const { actions } = useAnimations(animations, group)
 
  // materials.Außen.metalness = 0.9; 
  // materials.Außen.roughness = 0.1; 

  useMemo(() => Object.values(nodes).forEach(obj =>
    obj.isMesh && 
    Object.assign(obj, { 
      castShadow: true, 
      receiveShadow: true, 
      needsUpdate: true })
  ), [nodes])

  useEffect(() => {
    if (visible)
      Object.keys(actions).forEach((key) => {
        actions[key].play().paused = true
      })
  }, [visible])

  useFrame(({ camera }) => {
    Object.keys(actions).forEach((key) => {
      actions[key].time = 
      THREE.MathUtils.lerp(actions[key].time, 
      actions[key].getClip().duration * (scroll.current * (1 / 0.7)), //(1 - (scroll.current + 0.3)), 
      0.05)  
    })
    
    if(props.theme === 'light'){
      materials.Plant.color.r = 0.4;
      materials.Plant.color.g = 0.4; 
      materials.Plant.color.b = 0.4;
      materials.Stem.color.r = 0.4;
      materials.Stem.color.g = 0.4; 
      materials.Stem.color.b = 0.4;
    }
    if(props.theme === 'green'){
      materials.Plant.color.r = 0.3;
      materials.Plant.color.g = 0.5; 
      materials.Plant.color.b = 0.3;
      materials.Stem.color.r = 0.3;
      materials.Stem.color.g = 0.5; 
      materials.Stem.color.b = 0.3;
    }
    if(props.theme === 'blue'){
      materials.Plant.color.r = 0.41;
      materials.Plant.color.g = 0.41; 
      materials.Plant.color.b = 0.41;
      materials.Stem.color.r = 0.41;
      materials.Stem.color.g = 0.41; 
      materials.Stem.color.b = 0.41;
    }
    if(props.theme === 'dark'){
      materials.Plant.color.r = 0.5;
      materials.Plant.color.g = 0.3; 
      materials.Plant.color.b = 0.3;
      materials.Stem.color.r = 0.5;
      materials.Stem.color.g = 0.3; 
      materials.Stem.color.b = 0.3;
    }
  })

  return( 
  <primitive 
  ref={group} 
  object={scene} 
  {...props} /> 
  )
}


function Libelle({ scroll, visible, ...props }) {
  const group = useRef()
  const { scene, nodes, animations, materials } = useGLTF('/Dragonfly.glb')
  const { actions } = useAnimations(animations, group)
 
  
  materials.Dragonfly_wings.metalness = 0
  materials.Dragonfly_wings.roughness = 0.5

  useMemo(() => Object.values(nodes).forEach(obj =>
    obj.isMesh && 
    Object.assign(obj, { 
      castShadow: true, 
      receiveShadow: true, 
      needsUpdate: true })
  ), [nodes])

  useEffect(() => {
    if (visible)
      Object.keys(actions).forEach((key) => {
        actions[key].play().paused = true
      })
  }, [visible])

  useFrame(({ camera }) => {
    Object.keys(actions).forEach((key) => {
      actions[key].time = 
      THREE.MathUtils.lerp(actions[key].time, 
      actions[key].getClip().duration * (scroll.current * (1 / 0.6)), //(1 - (scroll.current + 0.3)), 
      0.05)  
    })
  })

  return( 
  <primitive 
  ref={group} 
  object={scene} 
  {...props} /> 
  )
}