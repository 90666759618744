import React, { Component } from "react";
import { Link } from 'react-scroll';
import { NavLink } from "react-router-dom";
import { useTheme } from "../themes/themeContext";
var classNames = require('classnames');


export default function Header(){
  const { theme } = useTheme()

  const toggle = () => {
    document.getElementById("nav-content").classList.toggle("hidden");
  };

  let currentTheme = classNames({
    'text-light bg-dark': theme === 'light',
    'text-green bg-light': theme === 'green',
    'text-blue bg-light': theme === 'blue',
    'text-dark bg-light': theme === 'dark'
  })

  //lg:flex lg:items-center lg:w-80
  //w-full
  return (        
    <nav className="transition ease-out duration-700 flex justify-between flex-wrap lg:items-center rounded-lg lg:w-80 items-center pt-6 p-6 fixed w-80 z-10 top-0">
      <HeaderIcon theme={currentTheme}/>
      <div 
        onClick={toggle} 
        className={"text-gray hidden w-full bg-light flex-grow rounded"} 
        id="nav-content">       
        <ul className="list-reset justify-end flex-1 items-center">
          <MenuLinks title={'Home'} refTo='landingPage'/>    
          <MenuLinks title={'About'} refTo='introduction'/>
          <MenuLinks title={'Pricing'} refTo='pricing'/>        
          <NavElement theme={currentTheme} content={'Contact'} to={'/contact'}/>  
        </ul>        
      </div>      
    </nav>   
  );
}



export function HeaderContact(){
  const { theme } = useTheme()

  const toggle = () => {
    document.getElementById("nav-content").classList.toggle("hidden");
  };

  let currentTheme = classNames({
    'text-light bg-dark': theme === 'light',
    'text-green bg-light': theme === 'green',
    'text-blue bg-light': theme === 'blue',
    'text-dark bg-light': theme === 'dark'
  })

  return (        
    <nav className="flex justify-between flex-wrap lg:items-center rounded-lg lg:w-80 items-center pt-6 p-6 fixed w-80 z-10 top-0">
      <HeaderIcon theme={currentTheme}/>
      <div 
        onClick={toggle} 
        className={"text-gray hidden w-full bg-light flex-grow rounded"} 
        id="nav-content">       
        <ul className="list-reset justify-end flex-1 items-center">           
          <NavElement theme={currentTheme} content={'Home'} to={'/'}/>          
          <NavElement theme={currentTheme} content={'About'} to={'/'}/>      
          <NavElement theme={currentTheme} content={'Pricing'} to={'/'}/>    
          <NavElement theme={currentTheme} content={'Contact'} to={'/'}/>
        </ul>        
      </div>      
    </nav>   
  );
}


function NavElement({...props}) {
  return (  
    <li className="mr-3">
      <NavLink
        //containerId="containerElement"
        //className={classNames("inline-block no-underline py-2 px-4")}  
        className={classNames('nav-link inline-block no-underline py-2 px-4')} 
        to={props.to}>
        {props.content}
        {/* <MenuLinks title={''} refTo='introduction'/>  */}
      </NavLink>  
    </li>
  );
}


function MenuLinks({...props}) {

  const toggle = () => {
    document.getElementById("nav-content").classList.toggle("hidden");
  };

  return ( 
    <li className="mr-3">
      <Link 
        className={classNames((props.theme), "inline-block no-underline py-2 px-4")} 
        onClick={toggle}
        activeClass="active" 
        to={props.refTo}
        spy={true} 
        smooth={true} 
        duration={250} 
        containerId="containerElement"
        >
          {props.title}
      </Link>
    </li>    
  )
}


function HeaderIcon({...props}) {
  
  const toggle = () => {
    document.getElementById("nav-content").classList.toggle("hidden");
  };
  
  return (  
    <div className="block">
      <button 
        className={classNames((props.theme), "flex items-center px-3 py-2 rounded border-green")}
        onClick={toggle}
        id="nav-toggle" >
        <svg 
          className="fill-current h-3 w-3" 
          viewBox="0 0 20 20" 
          //xmlns="http://www.w3.org/2000/svg"
          >
        <title>Menu</title>
        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
        </svg>
      </button>
    </div>
  );
}

