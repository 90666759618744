import React, { Suspense, useRef, useState, useEffect, useMemo, forwardRef } from 'react'
import { Canvas, useThree, useFrame } from '@react-three/fiber'
import Model from './components/Model'
import Overlay from './pages/Overlay'
import { softShadows, useHelper, Plane, Box, Backdrop, ContactShadows } from '@react-three/drei'
import { BoxHelper, SpotLightHelper, PointLightHelper } from "three"
//import './index.css';
//https://github.com/pmndrs/react-postprocessing/blob/master/api.md
import { EffectComposer } from "@react-three/postprocessing"
import {GodRayEffect, Sun} from './components/GodRayEffect'
import { CircleLens } from './components/CircleLensEffect'
import { BadTVEffect } from './components/BadTVEffect';
import {
    BrightnessContrastEffect, 
    HueSaturationEffect, 
    PixelationEffect,
    DotScreenEffect,
    SepiaEffect,
    VignetteEffect,
    SelectiveBloomEffect,
    OutlineEffect,
    BloomEffect,
    NoiseEffect,
    ScanlineEffect,
    ToneMappingEffect,
    ColorAverageEffect,
    GridEffect,
    DepthOfFieldEffect
    // BokehScaleEffect
} from './components/Effects'
import { 
    ShaderModel, 
    DistortModel, 
    ReflectorModel, 
    WobbleModel 
 } from './components/ModelMaterials';
import './index.css'
import { useTheme } from "./themes/themeContext";
var classNames = require('classnames');





export default function App() {
  const { theme } = useTheme()
  
  const overlay = useRef()
  const caption = useRef()
  const scroll = useRef(0)
  const spotLight = useRef()
  
  const [modelScale, setModelScale] = useState(0.1)
  const [modelPosition, setModelPosition] = useState([0, 1, 2.35])
  const [backdropPosition, setBackdropPosition] = useState([0, 0.3, -3])
  
  const [current, sunRef] = useState();
  const showGodray = false;
  
  let currentTheme = classNames({
    '#2e2e2e': theme === 'light',
    '#26402d': theme === 'green',
    '#323a54': theme === 'blue',
    '#4a2a2f': theme === 'dark',
  })


  useEffect(()=>{
    //sets scaling of model for mobile
    if(window.innerWidth < 500){
      setModelScale(0.08)
      setModelPosition([0, 0.9, 2.35])
      setBackdropPosition([0, 0.3, -3])
    }
    else{
      setModelScale(0.2)
      setModelPosition([0, 1, 2])
      setBackdropPosition([0, -0.25, -3])
    }
  }, [])


  
  return (
    <>
      <Canvas
        colorManagement
        shadows
        gl={{
          powerPreference: "high-performance",
          antialias: true,
          stencil: true,
          depth: true
        }} 
        camera={{ 
          aspect:0.1,
          fov: 50, 
          position: [0, 1, 3.5] 
        }}
        style={{
          background: currentTheme
        }} 
      >
      <ambientLight intensity={0.4} />
      <spotLight
        intensity={0.2}
        position={[0, 1, 3.5] }
      />
      <directionalLight
        position={[2, 3, 2]}
        intensity={0.5}
        castShadow
        shadow-mapSize-height={2048}
        shadow-mapSize-width={2048}
        />
      
        <Suspense fallback={null}>
          <Model 
          receiveShadow
          castShadow
          scroll={scroll} 
          theme={theme} 
          position={modelPosition} 
          scale={modelScale}/>
          {/* <ShaderModel/> */}
          {/* <DistortModel/> */}
          {/* <WobbleModel/> */}
          {/* <ReflectorModel/> */}
        </Suspense> 
        
        <Backdrop castShadow receiveShadow floor={2} position={backdropPosition} scale={[50, 10, 4]}>
          <meshStandardMaterial color={currentTheme}envMapIntensity={0.3} />
        </Backdrop>
        <ContactShadows position={backdropPosition}  scale={1} blur={1.5} far={1} />

        <EffectComposer>
          <ColorAverageEffect visible={true}/>
            {/* {current && (
            <GodRayEffect 
                sun={current}
                visible={showGodray}
            />
            )}
            <NoiseEffect visible={false}/>
            <BloomEffect  visible={false}/>
            <VignetteEffect visible={false}/>
            <SepiaEffect visible={false}/>
            <DotScreenEffect visible={false}/>
            <PixelationEffect visible={false}/>
            <HueSaturationEffect visible={false}/>
            <BrightnessContrastEffect visible={false}/>
            <CircleLens fragments={10} visible={false}/>
            <ToneMappingEffect visible={false}/>
            <ScanlineEffect visible={false}/>
            
            <GridEffect visible={false}/>
            <DepthOfFieldEffect visible={false}/>
            <BadTVEffect visible={false} distortion={5} distortion2={0.1} speed={0.1} rollSpeed={0} /> */}
            {/* <BokehScaleEffect/> */}
        </EffectComposer>
      </Canvas>
    <Overlay ref={overlay} caption={caption} scroll={scroll} />
    </>
  )
}