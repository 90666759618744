import React, { forwardRef } from "react"
import FooterModern from "./FooterModern";
import { HeaderContact } from "./Header";
// import {Link, scrollSpy, Events, Element, animateScroll} from 'react-scroll';
import { useTheme } from "../themes/themeContext";

var classNames = require('classnames');


export default function Datenschutz({...props}){
  const { theme } = useTheme()
    
  let currentTheme = classNames({
      'bg-light text-dark': theme === 'light',
      'bg-green text-light': theme === 'green',
      'bg-blue text-light': theme === 'blue',
      'bg-dark text-light': theme === 'dark'
  })

  return(
  <div
    ref={props.ref}
    onScroll={(e) => {
      props.scroll = e.target.scrollTop / (e.target.scrollHeight - window.innerHeight)
    }}
    className="scroll"
    id="containerElement"
    >     
      
    <HeaderContact/>
    <Privacy theme={currentTheme}/>
    <FooterModern/> 
   
  </div>
  )
}


function Privacy({...props}){
  return(
  <div className="flex">
  <div className={classNames((props.theme),"px-10 pt-32 py-10 pr-10 lg:px-32 xl:px-40")}>
      <h1 className="text-3xl font-medium font-sans mb-6">
      <span className="text-white">Datenschutz­erklärung</span>
      </h1>
      <p className={"text-s mb-6 mr-10"}>
        Diese Website verwendet KEINE Cookies. Wir haben 
        den Service von https://www.adsimple.de/ für unsere Datenschutzerklärung genutzt.
      </p>
      <br/>
      <h1 className="text-xl font-medium font-sans mb-6 mr-10">
      <span className="text-white">Einleitung und Überblick</span>
      </h1>
      <br/>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Wir haben diese Datenschutzerklärung (Fassung 15.02.2022-311946399) verfasst, 
      um Ihnen gemäß der Vorgaben der Datenschutz-Grundverordnung (EU) 2016/679 und 
      anwendbaren nationalen Gesetzen zu erklären, welche personenbezogenen Daten 
      (kurz Daten) wir als Verantwortliche – und die von uns beauftragten 
      Auftragsverarbeiter (z.B. Provider) – verarbeiten, zukünftig verarbeiten 
      werden und welche rechtmäßigen Möglichkeiten Sie haben. Die verwendeten 
      Begriffe sind geschlechtsneutral zu verstehen.
      </p>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Kurz gesagt: Wir informieren Sie umfassend über Daten, die wir über Sie 
      verarbeiten.
      </p>
      <br/>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Datenschutzerklärungen klingen für gewöhnlich sehr technisch und verwenden 
      juristische Fachbegriffe. Diese Datenschutzerklärung soll Ihnen hingegen die 
      wichtigsten Dinge so einfach und transparent wie möglich beschreiben. Soweit 
      es der Transparenz förderlich ist, werden technische Begriffe leserfreundlich 
      erklärt, Links zu weiterführenden Informationen geboten und Grafiken zum 
      Einsatz gebracht. Wir informieren damit in klarer und einfacher Sprache, dass 
      wir im Rahmen unserer Geschäftstätigkeiten nur dann personenbezogene Daten 
      verarbeiten, wenn eine entsprechende gesetzliche Grundlage gegeben ist. Das 
      ist sicher nicht möglich, wenn man möglichst knappe, unklare und 
      juristisch-technische Erklärungen abgibt, so wie sie im Internet oft Standard 
      sind, wenn es um Datenschutz geht. Ich hoffe, Sie finden die folgenden 
      Erläuterungen interessant und informativ und vielleicht ist die eine oder 
      andere Information dabei, die Sie noch nicht kannten.
      </p>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Wenn trotzdem Fragen bleiben, möchten wir Sie bitten, sich an die unten bzw. 
      im Impressum genannte verantwortliche Stelle zu wenden, den vorhandenen Links 
      zu folgen und sich weitere Informationen auf Drittseiten anzusehen. Unsere 
      Kontaktdaten finden Sie selbstverständlich auch im Impressum.
      </p>
      <br/>
      <h1 className="text-xl font-medium font-sans mb-6 mr-10">
      <span className="text-white">Anwendungsbereich</span>
      </h1>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Diese Datenschutzerklärung gilt für alle von uns im Unternehmen verarbeiteten 
      personenbezogenen Daten und für alle personenbezogenen Daten, die von uns 
      beauftragte Firmen (Auftragsverarbeiter) verarbeiten. Mit personenbezogenen 
      Daten meinen wir Informationen im Sinne des Art. 4 Nr. 1 DSGVO wie zum 
      Beispiel Name, E-Mail-Adresse und postalische Anschrift einer Person. Die 
      Verarbeitung personenbezogener Daten sorgt dafür, dass wir unsere 
      Dienstleistungen und Produkte anbieten und abrechnen können, sei es online 
      oder offline. Der Anwendungsbereich dieser Datenschutzerklärung umfasst:
      alle Onlineauftritte (Websites, Onlineshops), die wir betreiben,
      Social Media Auftritte und E-Mail-Kommunikation,
      mobile Apps für Smartphones und andere Geräte
      </p>
      <br/>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Kurz gesagt: Die Datenschutzerklärung gilt für alle Bereiche, in denen 
      personenbezogene Daten im Unternehmen über die genannten Kanäle strukturiert 
      verarbeitet werden. Sollten wir außerhalb dieser Kanäle mit Ihnen in 
      Rechtsbeziehungen eintreten, werden wir Sie gegebenenfalls gesondert 
      informieren.
      </p>
      <br/>
      <h1 className="text-xl font-s font-sans mb-6 mr-10">
      <span className="text-white">Rechtsgrundlagen</span>
      </h1>
      <p className="text-gray-600 text-s mb-6 mr-10">
      In der folgenden Datenschutzerklärung geben wir Ihnen transparente Informationen 
      zu den rechtlichen Grundsätzen und Vorschriften, also den Rechtsgrundlagen der 
      Datenschutz-Grundverordnung, die uns ermöglichen, personenbezogene Daten zu 
      verarbeiten.
      Was das EU-Recht betrifft, beziehen wir uns auf die VERORDNUNG (EU) 2016/679 
      DES EUROPÄISCHEN PARLAMENTS UND DES RATES vom 27. April 2016. Diese 
      Datenschutz-Grundverordnung der EU können Sie selbstverständlich online auf 
      EUR-Lex, dem Zugang zum EU-Recht, unter 
      https://eur-lex.europa.eu/legal-content/DE/TXT/?uri=celex%3A32016R0679 
      nachlesen.</p>
      <br/>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Wir verarbeiten Ihre Daten nur, wenn mindestens eine der folgenden Bedingungen 
      zutrifft:
      </p>
      <br/>
      <p className="text-gray-600 text-s mb-6 mr-10">
        1) Einwilligung (Artikel 6 Absatz 1 lit. a DSGVO): Sie haben uns Ihre 
        Einwilligung gegeben, Daten zu einem bestimmten Zweck zu verarbeiten. Ein 
        Beispiel wäre die Speicherung Ihrer eingegebenen Daten eines Kontaktformulars.
      </p>
      <br/>
      <p className="text-gray-600 text-s mb-6 mr-10">
        2) Vertrag (Artikel 6 Absatz 1 lit. b DSGVO): Um einen Vertrag oder 
        vorvertragliche Verpflichtungen mit Ihnen zu erfüllen, verarbeiten wir Ihre 
        Daten. Wenn wir zum Beispiel einen Kaufvertrag mit Ihnen abschließen, 
        benötigen wir vorab personenbezogene Informationen.
      </p>
      <br/>
      <p className="text-gray-600 text-s mb-6 mr-10">
        3) Rechtliche Verpflichtung (Artikel 6 Absatz 1 lit. c DSGVO): Wenn wir einer 
        rechtlichen Verpflichtung unterliegen, verarbeiten wir Ihre Daten. Zum 
        Beispiel sind wir gesetzlich verpflichtet Rechnungen für die Buchhaltung 
        aufzuheben. Diese enthalten in der Regel personenbezogene Daten.
      </p>
      <br/>
      <p className="text-gray-600 text-s mb-6 mr-10">
        4) Berechtigte Interessen (Artikel 6 Absatz 1 lit. f DSGVO): Im Falle 
        berechtigter Interessen, die Ihre Grundrechte nicht einschränken, behalten 
        wir uns die Verarbeitung personenbezogener Daten vor. Wir müssen zum 
        Beispiel gewisse Daten verarbeiten, um unsere Website sicher und 
        wirtschaftlich effizient betreiben zu können. Diese Verarbeitung ist somit 
        ein berechtigtes Interesse.
      </p>
      <br/>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im öffentlichen 
      Interesse und Ausübung öffentlicher Gewalt sowie dem Schutz lebenswichtiger 
      Interessen treten bei uns in der Regel nicht auf. Soweit eine solche 
      Rechtsgrundlage doch einschlägig sein sollte, wird diese an der entsprechenden 
      Stelle ausgewiesen.
      </p>
      <br/>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Zusätzlich zu der EU-Verordnung gelten auch noch nationale Gesetze: In 
      Österreich ist dies das Bundesgesetz zum Schutz natürlicher Personen bei der 
      Verarbeitung personenbezogener Daten (Datenschutzgesetz), kurz DSG. In 
      Deutschland gilt das Bundesdatenschutzgesetz, kurz BDSG. Sofern weitere 
      regionale oder nationale Gesetze zur Anwendung kommen, informieren wir Sie in 
      den folgenden Abschnitten darüber.
      </p>
      <br/>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Sollten Sie Fragen zum Datenschutz haben, kontaktieren Sie die im Impressum 
      angegebene Person bzw. Stelle. 
      </p>
      <br/>
      <h1 className="text-xl font-s font-sans mb-6 mr-10">
      <span className="text-white">Speicherdauer</span>
      </h1>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Dass wir personenbezogene Daten nur so lange speichern, wie es für die 
      Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist, 
      gilt als generelles Kriterium bei uns. Das bedeutet, dass wir personenbezogene 
      Daten löschen, sobald der Grund für die Datenverarbeitung nicht mehr vorhanden 
      ist. In einigen Fällen sind wir gesetzlich dazu verpflichtet, bestimmte Daten 
      auch nach Wegfall des ursprüngliches Zwecks zu speichern, zum Beispiel zu 
      Zwecken der Buchführung.
      </p>
      <br/>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Sollten Sie die Löschung Ihrer Daten wünschen oder die Einwilligung zur 
      Datenverarbeitung widerrufen, werden die Daten so rasch wie möglich und soweit 
      keine Pflicht zur Speicherung besteht, gelöscht.
      </p>
      <br/>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Über die konkrete Dauer der jeweiligen Datenverarbeitung informieren wir 
      Sie weiter unten, sofern wir weitere Informationen dazu haben.
      </p>
      <br/>

      <h1 className="text-xl font-s font-sans mb-6 mr-10">
      <span className="text-white">Rechte laut Datenschutz-Grundverordnung</span>
      </h1>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Laut Artikel 13 DSGVO stehen Ihnen die folgenden Rechte zu, damit es zu einer 
      fairen und transparenten Verarbeitung von Daten kommt:
      </p>
      <br/>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Sie haben laut Artikel 15 DSGVO ein Auskunftsrecht darüber, ob wir Daten von 
      Ihnen verarbeiten. Sollte das zutreffen, haben Sie Recht darauf eine Kopie der 
      Daten zu erhalten und die folgenden Informationen zu erfahren:
      zu welchem Zweck wir die Verarbeitung durchführen;
      die Kategorien, also die Arten von Daten, die verarbeitet werden;
      wer diese Daten erhält und wenn die Daten an Drittländer übermittelt werden, 
      wie die Sicherheit garantiert werden kann;
      wie lange die Daten gespeichert werden;
      das Bestehen des Rechts auf Berichtigung, Löschung oder Einschränkung der 
      Verarbeitung und dem Widerspruchsrecht gegen die Verarbeitung;
      dass Sie sich bei einer Aufsichtsbehörde beschweren können (Links zu diesen 
      Behörden finden Sie weiter unten);
      die Herkunft der Daten, wenn wir sie nicht bei Ihnen erhoben haben;
      ob Profiling durchgeführt wird, ob also Daten automatisch ausgewertet werden, 
      um zu einem persönlichen Profil von Ihnen zu gelangen.
      </p>
      <br/>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Sie haben laut Artikel 16 DSGVO ein Recht auf Berichtigung der Daten, was 
      bedeutet, dass wir Daten richtig stellen müssen, falls Sie Fehler finden.
      </p>
      <br/>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Sie haben laut Artikel 17 DSGVO das Recht auf Löschung („Recht auf 
      Vergessenwerden“), was konkret bedeutet, dass Sie die Löschung Ihrer Daten 
      verlangen dürfen.
      </p>
      <br/>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Sie haben laut Artikel 18 DSGVO das Recht auf Einschränkung der Verarbeitung, 
      was bedeutet, dass wir die Daten nur mehr speichern dürfen aber nicht weiter 
      verwenden.
      </p>
      <br/>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Sie haben laut Artikel 19 DSGVO das Recht auf Datenübertragbarkeit, was 
      bedeutet, dass wir Ihnen auf Anfrage Ihre Daten in einem gängigen Format zur 
      Verfügung stellen.
      </p>
      <br/>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Sie haben laut Artikel 21 DSGVO ein Widerspruchsrecht, welches nach 
      Durchsetzung eine Änderung der Verarbeitung mit sich bringt.
      Wenn die Verarbeitung Ihrer Daten auf Artikel 6 Abs. 1 lit. e (öffentliches 
      Interesse, Ausübung öffentlicher Gewalt) oder Artikel 6 Abs. 1 lit. f 
      (berechtigtes Interesse) basiert, können Sie gegen die Verarbeitung Widerspruch 
      einlegen. Wir prüfen danach so rasch wie möglich, ob wir diesem Widerspruch 
      rechtlich nachkommen können.
      Werden Daten verwendet, um Direktwerbung zu betreiben, können Sie jederzeit 
      gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten 
      danach nicht mehr für Direktmarketing verwenden.
      Werden Daten verwendet, um Profiling zu betreiben, können Sie jederzeit gegen 
      diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach 
      nicht mehr für Profiling verwenden.
      </p>
      <br/>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Sie haben laut Artikel 22 DSGVO unter Umständen das Recht, nicht einer 
      ausschließlich auf einer automatisierten Verarbeitung (zum Beispiel Profiling) 
      beruhenden Entscheidung unterworfen zu werden.
      </p>
      <br/>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht 
      verstößt oder Ihre datenschutzrechtlichen Ansprüche in sonst einer Weise 
      verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren. 
      Diese ist für Österreich die Datenschutzbehörde, deren Website Sie unter 
      https://www.dsb.gv.at/ finden. In Deutschland gibt es für jedes Bundesland 
      einen Datenschutzbeauftragten. Für nähere Informationen können Sie sich an die 
      Bundesbeauftragte für den Datenschutz und die Informationsfreiheit (BfDI) 
      wenden. Für unser Unternehmen ist die folgende lokale Datenschutzbehörde 
      zuständig:
      </p>
      <br/>

      <h1 className="text-xl font-s font-sans mb-6 mr-10">
      <span className="text-white">Datenübertragung in Drittländer</span>
      </h1>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Wir übertragen oder verarbeiten Daten nur dann in Länder außerhalb der EU 
      (Drittländer), wenn Sie dieser Verarbeitung zustimmen, dies gesetzlich 
      vorgeschrieben ist oder vertraglich notwendig und in jedem Fall nur soweit 
      dies generell erlaubt ist. Ihre Zustimmung ist in den meisten Fällen der 
      wichtigste Grund, dass wir Daten in Drittländern verarbeiten lassen. Die 
      Verarbeitung personenbezogener Daten in Drittländern wie den USA, wo viele 
      Softwarehersteller Dienstleistungen anbieten und Ihre Serverstandorte haben, 
      kann bedeuten, dass personenbezogene Daten auf unerwartete Weise verarbeitet 
      und gespeichert werden.
      </p>
      <br/>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Wir weisen ausdrücklich darauf hin, dass nach Meinung des Europäischen 
      Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in 
      die USA besteht. Die Datenverarbeitung durch US-Dienste (wie beispielsweise 
      Google Analytics) kann dazu führen, dass gegebenenfalls Daten nicht anonymisiert 
      verarbeitet und gespeichert werden. Ferner können gegebenenfalls US-amerikanische 
      staatliche Behörden Zugriff auf einzelne Daten nehmen. Zudem kann es vorkommen, 
      dass erhobene Daten mit Daten aus anderen Diensten desselben Anbieters, sofern 
      Sie ein entsprechendes Nutzerkonto haben, verknüpft werden. Nach Möglichkeit 
      versuchen wir Serverstandorte innerhalb der EU zu nutzen, sofern das angeboten 
      wird.
      </p>
      <br/>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Wir informieren Sie an den passenden Stellen dieser Datenschutzerklärung 
      genauer über Datenübertragung in Drittländer, sofern diese zutrifft.
      </p>
      <br/>

      <h1 className="text-xl font-s font-sans mb-6 mr-10">
      <span className="text-white">Sicherheit der Datenverarbeitung</span>
      </h1>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Um personenbezogene Daten zu schützen, haben wir sowohl technische als auch 
      organisatorische Maßnahmen umgesetzt. Wo es uns möglich ist, verschlüsseln 
      oder pseudonymisieren wir personenbezogene Daten. Dadurch machen wir es im 
      Rahmen unserer Möglichkeiten so schwer wie möglich, dass Dritte aus unseren 
      Daten auf persönliche Informationen schließen können.
      </p>
      <br/>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Art. 25 DSGVO spricht hier von “Datenschutz durch Technikgestaltung und durch 
      datenschutzfreundliche Voreinstellungen” und meint damit, dass man sowohl bei 
      Software (z. B. Formularen) also auch Hardware (z. B. Zugang zum Serverraum) 
      immer an Sicherheit denkt und entsprechende Maßnahmen setzt. Im Folgenden gehen 
      wir, falls erforderlich, noch auf konkrete Maßnahmen ein.
      </p>
      <br/>

      <h1 className="text-xl font-medium font-sans mb-6 mr-10">
      <span className="text-white">Kommunikation</span>
      </h1>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Wenn Sie mit uns Kontakt aufnehmen und per Telefon, E-Mail oder Online-Formular 
      kommunizieren, kann es zur Verarbeitung personenbezogener Daten kommen.
      Die Daten werden für die Abwicklung und Bearbeitung Ihrer Frage und des damit 
      zusammenhängenden Geschäftsvorgangs verarbeitet. Die Daten während ebensolange 
      gespeichert bzw. so lange es das Gesetz vorschreibt.
      </p>       
      <br/>        
      <h1 className="text-m font-s font-sans mb-6 mr-10">
      <span className="text-white">Betroffene Personen</span>
      </h1>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Von den genannten Vorgängen sind alle betroffen, die über die von uns bereit 
      gestellten Kommunikationswege den Kontakt zu uns suchen.
      </p>        
      <br/>       
      <h1 className="text-m font-s font-sans mb-6 mr-10">
      <span className="text-white">Telefon</span>
      </h1>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Wenn Sie uns anrufen, werden die Anrufdaten auf dem jeweiligen Endgerät und beim 
      eingesetzten Telekommunikationsanbieter pseudonymisiert gespeichert. Außerdem 
      können Daten wie Name und Telefonnummer im Anschluss per E-Mail versendet und 
      zur Anfragebeantwortung gespeichert werden. Die Daten werden gelöscht, sobald der 
      Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.
      </p>      
      <br/>        
      <h1 className="text-m font-s font-sans mb-6 mr-10">
      <span className="text-white">E-Mail</span>
      </h1>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Wenn Sie mit uns per E-Mail kommunizieren, werden Daten gegebenenfalls auf dem 
      jeweiligen Endgerät (Computer, Laptop, Smartphone,…) gespeichert und es kommt 
      zur Speicherung von Daten auf dem E-Mail-Server. Die Daten werden gelöscht, 
      sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.
      </p>             
      <br/> 
      <h1 className="text-m font-s font-sans mb-6 mr-10">
      <span className="text-white">Online Formulare</span>
      </h1>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Wenn Sie mit uns mittels Online-Formular kommunizieren, werden Daten auf unserem 
      Webserver gespeichert und gegebenenfalls an eine E-Mail-Adresse von uns 
      weitergeleitet. Die Daten werden gelöscht, sobald der Geschäftsfall beendet 
      wurde und es gesetzliche Vorgaben erlauben.
      </p>      
      <br/> 
      <h1 className="text-m font-s font-sans mb-6 mr-10">
      <span className="text-white">Rechtsgrundlagen</span>
      </h1>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Die Verarbeitung der Daten basiert auf den folgenden Rechtsgrundlagen:
      </p> 
      <br/>
      <p className="text-gray-600 text-s mb-6 mr-10">            
      Art. 6 Abs. 1 lit. a DSGVO (Einwilligung): Sie geben uns die Einwilligung Ihre 
      Daten zu speichern und weiter für den Geschäftsfall betreffende Zwecke zu verwenden;
      </p>
      <br/>
      <p className="text-gray-600 text-s mb-6 mr-10">            
      Art. 6 Abs. 1 lit. b DSGVO (Vertrag): Es besteht die Notwendigkeit für die Erfüllung 
      eines Vertrags mit Ihnen oder einem Auftragsverarbeiter wie z. B. dem Telefonanbieter 
      oder wir müssen die Daten für vorvertragliche Tätigkeiten, wie z. B. die Vorbereitung 
      eines Angebots, verarbeiten;
      </p>
      <br/>
      <p className="text-gray-600 text-s mb-6 mr-10">            
      Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen): Wir wollen Kundenanfragen und 
      geschäftliche Kommunikation in einem professionellen Rahmen betreiben. Dazu sind 
      gewisse technische Einrichtungen wie z. B. E-Mail-Programme, Exchange-Server und 
      Mobilfunkbetreiber notwendig, um die Kommunikation effizient betreiben zu können.
      </p>
      <br/>

      <h1 className="text-xl font-medium font-sans mb-6 mr-10">
      <span className="text-white">Cookies</span>
      </h1>           
      <br/> 
      <h1 className="text-m font-s font-sans mb-6 mr-10">
      <span className="text-white">Was sind Cookies?</span>
      </h1>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Unsere Website verwendet keine HTTP-Cookies, um nutzerspezifische Daten zu speichern.
      Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt werden, damit Sie die 
      folgende Datenschutzerklärung besser verstehen.
      </p>  
      <br/>
      <p className="text-gray-600 text-s mb-6 mr-10">            
      Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte Browser 
      sind beispielsweise Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge. Die 
      meisten Websites speichern kleine Text-Dateien in Ihrem Browser. Diese Dateien nennt man 
      Cookies.
      </p> 
      <br/>
      <p className="text-gray-600 text-s mb-6 mr-10">            
      Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche Helferlein. Fast 
      alle Websites verwenden Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch 
      noch andere Cookies für andere Anwendungsbereiche gibt. HTTP-Cookies sind kleine Dateien, 
      die von unserer Website auf Ihrem Computer gespeichert werden. Diese Cookie-Dateien 
      werden automatisch im Cookie-Ordner, quasi dem “Hirn” Ihres Browsers, untergebracht. 
      Ein Cookie besteht aus einem Namen und einem Wert. Bei der Definition eines Cookies 
      müssen zusätzlich ein oder mehrere Attribute angegeben werden.
      </p>
      <br/>
      <p className="text-gray-600 text-s mb-6 mr-10">            
      Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder 
      persönliche Seiteneinstellungen. Wenn Sie unsere Seite wieder aufrufen, übermittelt 
      Ihr Browser die „userbezogenen“ Informationen an unsere Seite zurück. Dank der Cookies 
      weiß unsere Website, wer Sie sind und bietet Ihnen die Einstellung, die Sie gewohnt sind. 
      In einigen Browsern hat jedes Cookie eine eigene Datei, in anderen wie beispielsweise 
      Firefox sind alle Cookies in einer einzigen Datei gespeichert.
      </p>
      <br/>
      <p className="text-gray-600 text-s mb-6 mr-10">            
      Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies 
      werden direkt von unserer Seite erstellt, Drittanbieter-Cookies werden von 
      Partner-Websites (z.B. Google Analytics) erstellt. Jedes Cookie ist individuell zu 
      bewerten, da jedes Cookie andere Daten speichert. Auch die Ablaufzeit eines Cookies 
      variiert von ein paar Minuten bis hin zu ein paar Jahren. Cookies sind keine 
      Software-Programme und enthalten keine Viren, Trojaner oder andere „Schädlinge“. 
      Cookies können auch nicht auf Informationen Ihres PCs zugreifen.
      </p>          
      <br/> 
      <h1 className="text-m font-s font-sans mb-6 mr-10">
      <span className="text-white">Welche Arten von Cookies gibt es?</span>
      </h1>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Die Frage welche Cookies wir im Speziellen verwenden, hängt von den verwendeten Diensten 
      ab und wird in den folgenden Abschnitten der Datenschutzerklärung geklärt. An dieser 
      Stelle möchten wir kurz auf die verschiedenen Arten von HTTP-Cookies eingehen. Man kann 
      4 Arten von Cookies unterscheiden:
      </p>      
      <br/> 
      <h1 className="text-m font-s font-sans mb-6 mr-10">
      <span className="text-white">Unerlässliche Cookies</span>
      </h1>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Diese Cookies sind nötig, um grundlegende Funktionen der Website sicherzustellen. 
      Zum Beispiel braucht es diese Cookies, wenn ein User ein Produkt in den Warenkorb legt, 
      dann auf anderen Seiten weitersurft und später erst zur Kasse geht. Durch diese Cookies 
      wird der Warenkorb nicht gelöscht, selbst wenn der User sein Browserfenster schließt.
      </p>       
      <br/> 
      <h1 className="text-m font-s font-sans mb-6 mr-10">
      <span className="text-white">Zweckmäßige Cookies</span>
      </h1>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Diese Cookies sammeln Infos über das Userverhalten und ob der User etwaige 
      Fehlermeldungen bekommt. Zudem werden mithilfe dieser Cookies auch die Ladezeit und das 
      Verhalten der Website bei verschiedenen Browsern gemessen.
      </p>       
      <br/> 
      <h1 className="text-m font-s font-sans mb-6 mr-10">
      <span className="text-white">Zielorientierte Cookies</span>
      </h1>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit. Beispielsweise werden 
      eingegebene Standorte, Schriftgrößen oder Formulardaten gespeichert.
      </p>        
      <br/> 
      <h1 className="text-m font-s font-sans mb-6">
      <span className="text-white">Werbe-Cookies</span>
      </h1>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem User 
      individuell angepasste Werbung zu liefern. Das kann sehr praktisch, aber auch sehr 
      nervig sein.

      Üblicherweise werden Sie beim erstmaligen Besuch einer Website gefragt, welche 
      dieser Cookiearten Sie zulassen möchten. Und natürlich wird diese Entscheidung 
      auch in einem Cookie gespeichert.

      Wenn Sie mehr über Cookies wissen möchten und technische Dokumentationen nicht 
      scheuen, empfehlen wir https://datatracker.ietf.org/doc/html/rfc6265, dem Request 
      for Comments der Internet Engineering Task Force (IETF) namens “HTTP State Management 
      Mechanism”.
      </p>       
      <br/> 
      <h1 className="text-l font-s font-sans mb-6 mr-10">
      <span className="text-white">Zweck der Verarbeitung über Cookies</span>
      </h1>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Der Zweck ist letztendlich abhängig vom jeweiligen Cookie. Mehr Details dazu 
      finden Sie weiter unten bzw. beim Hersteller der Software, die das Cookie setzt.
      </p>        
      <br/> 
      <h1 className="text-l font-s font-sans mb-6 mr-10">
      <span className="text-white">Welche Daten werden verarbeitet?</span>
      </h1>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Cookies sind kleine Gehilfen für eine viele verschiedene Aufgaben. Welche Daten 
      in Cookies gespeichert werden, kann man leider nicht verallgemeinern, aber wir 
      werden Sie im Rahmen der folgenden Datenschutzerklärung über die verarbeiteten bzw. 
      gespeicherten Daten informieren.
      </p>         
      <br/> 
      <h1 className="text-l font-s font-sans mb-6 mr-10">
      <span className="text-white">Speicherdauer von Cookies</span>
      </h1>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Die Speicherdauer hängt vom jeweiligen Cookie ab und wird weiter unter präzisiert. 
      Manche Cookies werden nach weniger als einer Stunde gelöscht, andere können mehrere 
      Jahre auf einem Computer gespeichert bleiben.

      Sie haben außerdem selbst Einfluss auf die Speicherdauer. Sie können über ihren 
      Browser sämtliche Cookies jederzeit manuell löschen (siehe auch unten “Widerspruchsrecht”).
      Ferner werden Cookies, die auf einer Einwilligung beruhen, spätestens nach Widerruf Ihrer 
      Einwilligung gelöscht, wobei die Rechtmäßigkeit der Speicherung bis dahin unberührt bleibt.
      </p>  
      <br/> 
      <h1 className="text-l font-s font-sans mb-6 mr-10">
      <span className="text-white">Widerspruchsrecht – wie kann ich Cookies löschen?</span>
      </h1>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig von welchem 
      Service oder welcher Website die Cookies stammen, haben Sie immer die Möglichkeit Cookies 
      zu löschen, zu deaktivieren oder nur teilweise zuzulassen. Zum Beispiel können Sie Cookies 
      von Drittanbietern blockieren, aber alle anderen Cookies zulassen.
      </p>  
      <br/>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten,
       dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei 
       jedem einzelnen Cookie entscheiden, ob Sie das Cookie erlauben oder nicht. Die 
       Vorgangsweise ist je nach Browser verschieden. Am besten Sie suchen die Anleitung in 
       Google mit dem Suchbegriff “Cookies löschen Chrome” oder “Cookies deaktivieren Chrome” im 
       Falle eines Chrome Browsers.
      </p> 
      <br/> 
      <h1 className="text-l font-s font-sans mb-6 mr-10">
      <span className="text-white">Rechtsgrundlage</span>
      </h1>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist festgehalten, dass das 
      Speichern von Cookies eine Einwilligung (Artikel 6 Abs. 1 lit. a DSGVO) von Ihnen verlangt. 
      Innerhalb der EU-Länder gibt es allerdings noch sehr unterschiedliche Reaktionen auf diese 
      Richtlinien. In Österreich erfolgte aber die Umsetzung dieser Richtlinie in § 96 Abs. 3 
      des Telekommunikationsgesetzes (TKG). In Deutschland wurden die Cookie-Richtlinien nicht 
      als nationales Recht umgesetzt. Stattdessen erfolgte die Umsetzung dieser Richtlinie 
      weitgehend in § 15 Abs.3 des Telemediengesetzes (TMG).
      </p>  
      <br/>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Für unbedingt notwendige Cookies, auch soweit keine Einwilligung vorliegt. bestehen 
      berechtigte Interessen (Artikel 6 Abs. 1 lit. f DSGVO), die in den meisten Fällen 
      wirtschaftlicher Natur sind. Wir möchten den Besuchern der Website eine angenehme 
      Benutzererfahrung bescheren und dafür sind bestimmte Cookies oft unbedingt notwendig.
      </p>
      <br/>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Soweit nicht unbedingt erforderliche Cookies zum Einsatz kommen, geschieht dies nur im 
      Falle Ihrer Einwilligung. Rechtsgrundlage ist insoweit Art. 6 Abs. 1 lit. a DSGVO.
      </p>
      
      <br/>

      <h1 className="text-xl font-medium font-sans mb-6 mr-10">
      <span className="text-white">Webhosting</span>
      </h1>           
      <br/> 
      <h1 className="text-m font-s font-sans mb-6 mr-10">
      <span className="text-white">Was ist Webhosting?</span>
      </h1>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Wenn Sie heutzutage Websites besuchen, werden gewisse Informationen – auch 
      personenbezogene Daten – automatisch erstellt und gespeichert, so auch auf dieser 
      Website. Diese Daten sollten möglichst sparsam und nur mit Begründung verarbeitet werden. 
      Mit Website meinen wir übrigens die Gesamtheit aller Webseiten auf einer Domain, d.h. 
      alles von der Startseite (Homepage) bis hin zur aller letzten Unterseite 
      (wie dieser hier). Mit Domain meinen wir zum Beispiel beispiel.de oder musterbeispiel.com.
      </p>  
      <br/>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Wenn Sie eine Website auf einem Bildschirm ansehen möchten, verwenden Sie dafür ein 
      Programm, das sich Webbrowser nennt. Sie kennen vermutlich einige Webbrowser beim Namen: 
      Google Chrome, Microsoft Edge, Mozilla Firefox und Apple Safari.
      </p> 
      <br/>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Dieser Webbrowser muss sich zu einem anderen Computer verbinden, wo der Code der 
      Website gespeichert ist: dem Webserver. Der Betrieb eines Webservers ist eine komplizierte 
      und aufwendige Aufgabe, weswegen dies in der Regel von professionellen Anbietern, den 
      Providern, übernommen wird. Diese bieten Webhosting an und sorgen damit für eine 
      verlässliche und fehlerfreie Speicherung der Daten von Websites.
      </p> 
      <br/>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Bei der Verbindungsaufnahme des Browsers auf Ihrem Computer (Desktop, Laptop, Smartphone) 
      und während der Datenübertragung zu und vom Webserver kann es zu einer Verarbeitung 
      personenbezogener Daten kommen. Einerseits speichert Ihr Computer Daten, andererseits 
      muss auch der Webserver Daten eine Zeit lang speichern, um einen ordentlichen Betrieb 
      zu gewährleisten.
      </p>       
      <br/> 
      <h1 className="text-m font-s font-sans mb-6 mr-10">
      <span className="text-white">Warum verarbeiten wir personenbezogene Daten?</span>
      </h1>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Die Zwecke der Datenverarbeitung sind:
      </p> 
      <br/>
      <p className="text-gray-600 text-s mb-6 mr-10">
      1) Professionelles Hosting der Website und Absicherung des Betriebs
      </p> 
      <p className="text-gray-600 text-s mb-6 mr-10">
      2) zur Aufrechterhaltung der Betriebs- und IT-Sicherheit
      </p> 
      <p className="text-gray-600 text-s mb-6 mr-10">
      3) Anonyme Auswertung des Zugriffsverhaltens zur Verbesserung unseres Angebots und ggf. 
      zur Strafverfolgung bzw. Verfolgung von Ansprüchen
      </p>       
      <br/> 
      <h1 className="text-m font-s font-sans mb-6 mr-10">
      <span className="text-white">Welche Daten werden verarbeitet?</span>
      </h1>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Auch während Sie unsere Website jetzt gerade besuchen, speichert unser Webserver, das ist 
      der Computer auf dem diese Webseite gespeichert ist, in der Regel automatisch Daten wie 
      die komplette Internetadresse (URL) der aufgerufenen Webseite,
      Browser und Browserversion (z. B. Chrome 87),
      das verwendete Betriebssystem (z. B. Windows 10),
      die Adresse (URL) der zuvor besuchten Seite (Referrer URL) 
      (z. B. https://www.beispielquellsite.de/vondabinichgekommen.html/),
      den Hostnamen und die IP-Adresse des Geräts von welchem aus zugegriffen wird 
      (z. B. COMPUTERNAME und 194.23.43.121),
      Datum und Uhrzei,t in Dateien, den sogenannten Webserver-Logfiles.
      </p>       
      <br/> 
      <h1 className="text-m font-s font-sans mb-6  mr-10">
      <span className="text-white">Wie lange werden Daten gespeichert?</span>
      </h1>
      <p className="text-gray-600 text-s mb-6 mr-10">
      In der Regel werden die oben genannten Daten zwei Wochen gespeichert und danach 
      automatisch gelöscht. Wir geben diese Daten nicht weiter, können jedoch nicht 
      ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten von Behörden 
      eingesehen werden.
      </p> 
      <br/>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Kurz gesagt: Ihr Besuch wird durch unseren Provider (Firma, die unsere Website auf 
      speziellen Computern (Servern) laufen lässt), protokolliert, aber wir geben Ihre Daten 
      nicht ohne Zustimmung weiter!
      </p>       
      <br/> 
      <h1 className="text-m font-s font-sans mb-6 mr-10">
      <span className="text-white">Rechtsgrundlage</span>
      </h1>
      <p className="text-gray-600 text-s mb-6 mr-10">
      Die Rechtmäßigkeit der Verarbeitung personenbezogener Daten im Rahmen des Webhosting 
      ergibt sich aus Art. 6 Abs. 1 lit. f DSGVO (Wahrung der berechtigten Interessen), denn 
      die Nutzung von professionellem Hosting bei einem Provider ist notwendig, um das 
      Unternehmen im Internet sicher und nutzerfreundlich präsentieren und Angriffe und 
      Forderungen hieraus gegebenenfalls verfolgen zu können.
      </p> 
      <br/> 
      <p className="text-gray-600 text-s mb-6 mr-10">
      Zwischen uns und dem Hostingprovider besteht in der Regel ein Vertrag über die 
      Auftragsverarbeitung gemäß Art. 28 f. DSGVO, der die Einhaltung von Datenschutz 
      gewährleistet und Datensicherheit garantiert.
      </p> 
  </div>  
  </div>
  )
}
