import React, { Component, useContext } from "react";
import { useTheme } from "../themes/themeContext";
var classNames = require('classnames');


export default function LandingPage(props) {
  const { theme } = useTheme() 

  let currentTheme = classNames({
    'text-white100': theme === 'light',
    'text-white200': theme === 'green',
    'text-white300': theme === 'blue',
    'text-white400': theme === 'dark'
  })

  return (    
    <section className="">
      <div 
      id="landingPage" 
      className="h-screen w-screen bg-cover bg-center flex justify-items-center items-center">
          <HeaderElement theme={currentTheme}/>
      </div>
    </section>   
  );
}


function HeaderElement({...props}) {
  
  return (  
    <div className="px-10 lg:px-32 xl:px-40">
      <h1 className="text-6xl md:text-9xl font-semibold mb-6">
        <span className={classNames((props.theme))}>
          Virtual Matter
        </span>
      </h1>
      <p className={classNames((props.theme), "text-xl max-w-md")}>
        your 3D web expert
      </p>
    </div>  
  );
}
