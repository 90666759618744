import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { useTheme } from "../themes/themeContext";
var classNames = require('classnames');

export default function AboutUs(){
    const { theme } = useTheme()
    
    let currentTheme = classNames({
        'text-white100': theme === 'light',
        'text-light': theme === 'green',
        'text-light': theme === 'blue',
        'text-light': theme === 'dark'
    })
        
    return (    
    <div>
        <IntroductionAgency theme={currentTheme}/>
    </div> 
    );
}


function IntroductionAgency({...props}) {
    return (  
        <div 
        className="flex justify-center w-full h-2/5"
        id="aboutUs">
            <div className={'text-white100 text-2xl w-2/4 pt-5 pb-5 mr-1 ml-1 mb-1 mt-1 sm:mb-10 sm:mt-10'}>
                <p className={'text-center'}>
                I empower you
                to present yourself in an innovative and interactive
                way with 3D content on the web. Supporting you from ideating
                to running and maintaining your web experience.
                </p>
            </div>
        </div>
    );
}