import React, { Component } from "react";

export function SpaceSmall(){
    
    return (    
    <div className="w-screen h-screen">
        
    </div> 
    );
}


export function SpaceMedium(){
    
    return (    
    <div className="w-screen h-screen">
        
    </div> 
    );
}

export function SpaceLarge(){
    return (    
    <div className="w-screen h-screen">
        
    </div> 
    );
}

//export default {SpaceSmall, SpaceMedium, SpaceLarge}