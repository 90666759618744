import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { useTheme } from "../themes/themeContext";
var classNames = require('classnames');

export default function Footer(){
    const { theme } = useTheme()
    
    let currentTheme = classNames({
        'bg-light text-dark': theme === 'light',
        'bg-green text-light': theme === 'green',
        'bg-blue text-light': theme === 'blue',
        'bg-dark text-light': theme === 'dark'
    })
        
    return (    
    <div className="footer">
        <footer className={classNames((currentTheme),'p-1 w-full pt-3 text-center')}>
            <div className="grid grid-cols-3 gap-4 w-50 text-center">
                <NavElement theme={currentTheme} content={'Start'} to={'/'}/>
                <NavElement theme={currentTheme} content={'Impressum'} to={'/impressum'}/>
                <NavElement theme={currentTheme} content={'Datenschutz'} to={'/datenschutz'}/>
            </div>
            <ContactElement theme={currentTheme}/>
            <InstaIcon theme={currentTheme}/>
        </footer>
    </div> 
    );
}


function NavElement({...props}) {
    return (  
        <NavLink 
            className={classNames((props.theme), 'nav-link font-bold text-xs')} 
            to={props.to}>
            {props.content}
        </NavLink>
    );
}


function ContactElement({...props}) {
    return (  
        <div className={'p-3 w-60 h-60 text-center'}>
            <p className={classNames((props.theme), 'font-bold text-xs')}>
            +49 (0)176 344 95 873
            </p>
            <p className={classNames((props.theme), 'font-bold text-xs')}>
            ripken@mail.de
            </p>
            <p className={classNames((props.theme), 'font-bold text-xs')}>
            Christine Ripken | Mommsenstr. 23 | 10629 Berlin
            </p>
        </div>
    );
}


function InstaIcon({...props}) {
    return (  
        <div className={'p-3 w-60 h-60 text-center'}>
            <span className="inline-flex">
                <button 
                onClick={(e) => {
                    e.preventDefault();
                    window.location.href='http://instagram.com/gardenstories_gartenbau?utm_medium=copy_link';
                    }}>
                    <svg
                        className="w-5 h-5"
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        >
                        <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                        <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                    </svg>
                </button>
            </span>
        </div>
    );
}

