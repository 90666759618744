import { useFrame } from "@react-three/fiber";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { useTheme } from "../themes/themeContext";
var classNames = require('classnames');

export default function Pricing(){
    const { theme } = useTheme()    
    
    let currentTheme = classNames({
        'bg-dark100 text-light hover:bg-dark300': theme === 'light',
        'bg-green100 text-light hover:bg-green300': theme === 'green',
        'bg-blue200 text-light hover:bg-blue300': theme === 'blue',
        'bg-dark text-light hover:bg-dark100': theme === 'dark'
    })

    let pricingBgTheme = classNames({
        'bg-dark200 text-light': theme === 'light',
        'bg-green200 text-light': theme === 'green',
        'bg-blue100 text-light': theme === 'blue',
        'bg-dark200 text-light': theme === 'dark'
    })
        
    let buttonBgTheme = classNames({
        'bg-light': theme === 'light',
        'bg-green': theme === 'green',
        'bg-blue': theme === 'blue',
        'bg-dark': theme === 'dark'
    })
        
    return (    
    <div className="pricing">
        <TechStack 
            theme={currentTheme} 
            pricingBgTheme={pricingBgTheme}
            backgroundTheme={buttonBgTheme}
            />
    </div> 
    );
}

 

function TechStack({...props}) {
    const contentBasic = [
        'One animated 3D-Model and Scene',
        'Landing page with informative content',
        'Three sub pages',
        'Hosting and Domain',
        'Contact Form',
        'One Shader for Postprocessing',
        'Menu'
    ];
    const contentEnterprise = [
        'Two animated 3D-Models and Scenes',
        'Landing page with informative content',
        'Up to six sub pages',
        'Hosting and Domain',
        'Contact Form',
        'Multiple Shaders for Postprocessing',
        'Menu'
    ];

    const contentPro = [
        'Mini-Game with First-Person-Controller',
        'Physics',
        'Interaction with Scene',
        'State Machine',
        'Graphical User Interface',
        'Backend-Services',
        'Maintanance'
    ];

    return (  
        <div 
        id='pricing'
        className={classNames(
            (props.backgroundTheme),
            "w-screen pt-5 pb-5")}
            >
            <div className="flex flex-wrap justify-center px-5 py-5 pt-20 pb-20">
                <PricingSign 
                    bgTheme={props.pricingBgTheme}
                    theme={props.theme}
                    level={'Basic'}
                    price={'from 4000 €'}
                    header={'Plan includes:'}
                    content={contentBasic}
                    font={'font-bold'}
                    fontSize={'text-md'}
                    />
                <PricingSign 
                    bgTheme={props.pricingBgTheme}
                    theme={props.theme}
                    level={'Enterprise'}
                    price={'from 7000 €'}
                    header={'Plan includes:'}
                    content={contentEnterprise}
                    font={'font-bold'}
                    fontSize={'text-md'}
                    />
                <PricingSign 
                    bgTheme={props.pricingBgTheme}
                    theme={props.theme}
                    level={'Pro'}
                    price={'custom pricing'}
                    header={'Custom three.js-related services e.g.:'}
                    content={contentPro}
                    font={'font-bold'}
                    fontSize={'text-md'}
                    />
            </div>
        </div>
    );
}


function PricingSign({...props}) {

    const listItems = props.content.map((content, index) =>
        <IncludedContent 
        key={'pricing' + index}
        service={content}
        font={'font-bold'}
        fontSize={'text-md md:text-xl'}
        />
    );

    return (  
    <div className={classNames(
        (props.bgTheme),
        (props.font), 
        (props.fontSize),
        "shadow-lg rounded-2xl sm:w-72 p-4 ml-1 mr-1 sm:ml-5 sm:mr-5 mt-5 mb-5")}
        >
        <div className="flex items-center justify-between">
            <p className={classNames(
                (props.bgTheme),
                (props.font), 
                (props.fontSize),
                "text-3xl font-bold mb-4")}
                >
                {props.level}
            </p>
            <p className="text-sm font-bold flex flex-col">
                {props.price}
            </p>
        </div>
        <p className="text-white font-bold mt-4 mb-4">
            {props.header}
        </p>
        <ul>
            {listItems}
        </ul>
        <button type="button" 
            className={classNames(
                (props.theme),
                (props.font), 
                (props.fontSize),
                "w-full px-3 py-3 text-sm shadow rounded-lg")}
                >         
            <NavElement theme={props.theme} content={'Get in Contact'} to={'/contact'}/>
        </button>
    </div>
    );
}


function NavElement({...props}) {
    return (  
        <NavLink 
          className={classNames('nav-link inline-block no-underline py-2 px-4')} 
          to={props.to}>
          {props.content}
        </NavLink> 
    );
  }
  

function IncludedContent({...props}) {
    return (  
    <li className={classNames(
        (props.font), 
        (props.fontSize), 
        "flex grow mb-3 items-center ")}
        >
        <AgreeIcon/>
        {props.service}
    </li>
    );
}

function AgreeIcon({...props}) {
    return (  
    <svg 
        className="flex-none h-6 w-6 mr-2" 
        xmlns="http://www.w3.org/2000/svg" 
        width="6" 
        height="6" 
        fill="currentColor" 
        viewBox="0 0 1792 1792"
        >
        <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
        </path>
    </svg>
    );
}


function CrossIcon({...props}) {
    return (  
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width="6" 
        height="6" 
        className="h-6 w-6 mr-2" 
        fill="white" 
        viewBox="0 0 1792 1792">
        <path d="M1277 1122q0-26-19-45l-181-181 181-181q19-19 19-45 0-27-19-46l-90-90q-19-19-46-19-26 0-45 19l-181 181-181-181q-19-19-45-19-27 0-46 19l-90 90q-19 19-19 46 0 26 19 45l181 181-181 181q-19 19-19 45 0 27 19 46l90 90q19 19 46 19 26 0 45-19l181-181 181 181q19 19 45 19 27 0 46-19l90-90q19-19 19-46zm387-226q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
        </path>
    </svg>
    );
}