import React, { forwardRef } from "react"
import Footer from "./Footer";
import FooterModern from "./FooterModern";
import {HeaderContact} from "./Header";
import { useTheme } from "../themes/themeContext";
import {Link, scrollSpy, Events, Element, animateScroll} from 'react-scroll';
import { useGLTF, useAnimations, useScroll } from '@react-three/drei'

var classNames = require('classnames');


export default function Impressum({...props}){
  const { theme } = useTheme()
    
  let currentTheme = classNames({
      'bg-light text-dark': theme === 'light',
      'bg-green text-light': theme === 'green',
      'bg-blue text-light': theme === 'blue',
      'bg-dark text-light': theme === 'dark'
  })

  return(
  <div
    ref={props.ref}
    onScroll={(e) => {
      props.scroll = e.target.scrollTop / (e.target.scrollHeight - window.innerHeight)
    }}
    className="scroll"
    id="containerElement"
    >     
    <HeaderContact theme={currentTheme}/>
    <ImprintContent theme={currentTheme}/>
    <Disclaimer theme={currentTheme}/>
    <FooterModern theme={currentTheme}/>   
   
  </div>
  )
}


function ImprintContent({...props}) {
  
  return (  
    <div className={classNames((props.theme), "flex")}>
      <div className="px-10 pb-10 pt-20 lg:px-32 xl:px-40">
        <h1 className="text-6xl font-medium font-sans mb-6">
        <span className="text-white">Imprint</span>
        </h1>
        <p className="text-gray-600 text-medium max-w-md">
        Information in accordance with section 5 TMG:
        </p>
        <br/>
        <p className="text-gray-600 text-s max-w-md">
          Christine Ripken
        </p>
        <p className="text-gray-600 text-s max-w-md">
          Mommsenstr. 23
        </p>
        <p className="text-gray-600 text-s max-w-md">
          10629 Berlin
        </p>
        <p className="text-gray-600 text-s max-w-md">
          Germany
        </p>
        <br/>        
        <p className="text-gray-600 text-s max-w-md">
          Mail: ripken@mail.de
        </p>      
        <p className="text-gray-600 text-s max-w-md">
          Web: https://virtual-matter-agency.com
        </p>
        <br/>
        <p className="text-gray-600 text-s max-w-md">
        Umsatzsteuer-IdNr: DE34 3764 941
        </p>
        <p className="text-gray-600 text-s max-w-md">
        Steuer-IdNr: 13/494/02203
        </p>
        <p className="text-gray-600 text-s max-w-md">
        Register Court: Berlin Charlottenburg
        </p>     
      </div>
    </div>   
  );
}



function Disclaimer({...props}) {
  
  return (  
    <div className={classNames((props.theme), "flex")}>
      <div className="px-10 pb-20 pt-5 lg:px-32 xl:px-40">
        <h1 className="max-w-md text-lg font-bold pb-5">
        Disclaimer
        </h1>       
        <p className="text-gray-600 text-md font-bold pb-2 max-w-md">
        Content Accountability
        </p>      
        <p className="text-gray-600 text-s max-w-md">          
        The contents of our pages have been created with the utmost care. However, 
        we cannot guarantee the contents’ accuracy, completeness or topicality. 
        According to statutory provisions, we are furthermore responsible for our 
        own content on these web pages. In this context, please note that we are 
        accordingly not obliged to monitor merely the transmitted or saved information 
        of third parties, or investigate circumstances pointing to illegal activity. 
        Our obligations to remove or block the use of information under generally 
        applicable laws remain unaffected by this as per §§ 8 to 10 of the 
        Telemedia Act (TMG).
        </p>
        <br/>
              
        <p className="text-gray-600 text-md pb-2 font-bold max-w-md">
        Copyright
        </p>      
        <p className="text-gray-600 text-s max-w-md">          
        Our web pages and their contents are subject to German copyright law. Unless 
        expressly permitted by law (§ 44a et seq. of the copyright law), every form of 
        utilizing, reproducing or processing works subject to copyright protection on 
        our web pages requires the prior consent of the respective owner of the rights. 
        Individual reproductions of a work are allowed only for private use, so must 
        not serve either directly or indirectly for earnings. Unauthorized utilization 
        of copyrighted works is punishable (§ 106 of the copyright law).
        </p>
        <br/>
              
        <p className="text-gray-600 text-md pb-2 font-bold max-w-md">
        Link Accountability
        </p>      
        <p className="text-gray-600 text-s max-w-md">          
        Responsibility for the content of external links (to web pages of third 
        parties) lies solely with the operators of the linked pages. No violations 
        were evident to us at the time of linking. Should any legal infringement 
        become known to us, we will remove the respective link immediately.
        </p>
        <br/>
      </div>
    </div>   
  );
}
