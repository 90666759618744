import React, { Component } from "react";
import { useTheme } from "../themes/themeContext";
var classNames = require('classnames');


export default function References(props) {
  const { theme, setTheme } = useTheme()

  return (        
  <section className="">
    <div className="container px-5 py-24 mx-auto h-screen">
      <HoverElement content={'I change'} theme={'dark'} />
      <HoverElement content={'the way'} theme={'green'}/>
      <HoverElement content={'you see'} theme={'blue'}/>
      <HoverElement content={'the web'} theme={'light'}/>
    </div>
  </section>
  );
}


function HoverElement({...props}) {
  const { theme, setTheme } = useTheme()
  
  return (  
    <div 
    className={'w-full px-10 lg:px-15 xl:px-20 h-32'}
    onMouseOver={() => setTheme(props.theme)}
    >  
      <h1 className={classNames({
      'text-white100': theme === 'light',
      'text-white200': theme === 'green',
      'text-white300': theme === 'blue',
      'text-white400': theme === 'dark',
      },"text-5xl lg:text-6xl font-sans text-bold mb-6 h-10")}>
      <span>
        {props.content}
      </span>
      </h1>
    </div>
  );
}