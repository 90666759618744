import React, { Component, useContext } from "react";
import { useTheme } from "../themes/themeContext";
import { NavLink } from "react-router-dom";

var classNames = require('classnames');


export default function ContactButton(props) {
  const { theme } = useTheme() 

  let currentTheme = classNames({
    'text-white100': theme === 'light',
    'text-white200': theme === 'green',
    'text-white300': theme === 'blue',
    'text-dark': theme === 'dark'
  })

  return (    
    <section className="">
      {/* <div id="contactButton" 
        className="h-screen bg-cover bg-center flex justify-items-center items-center"> */}
          <Button1 theme={currentTheme}/>
      {/* </div> */}
    </section>   
  );
}


function Button1({...props}) {  
  return (  
    <div 
      id="contactButton" 
      className="h-screen flex space-x-2 justify-center justify-items-center items-center">
        <div>
          <NavLink 
            className={classNames('nav-link font-bold text-xs')} 
            to={'/contact'}>
            <button 
            type="button" 
            className="inline-block px-6 py-5 font-bold bg-white100 text-dark text-lg md:text-m lg:text-lg xl:text-2xl leading-tight uppercase rounded-full shadow-md hover:bg-dark hover:text-white100 hover:shadow-lg transition duration-150 ease-in-out">
              Get in Contact
            </button>
          </NavLink>
        </div>
    </div>
  );
}
