import React, { forwardRef } from "react"
import Footer from "./Footer";
import Header from "./Header";
import {Link, scrollSpy, Events, Element, animateScroll} from 'react-scroll';
import LandingPage from './LandingPage'
import References from './References'
import Services from './Services'
import {SpaceSmall, SpaceMedium, SpaceLarge} from './Space'
import ContactButton from './ContactButton'
import Technologies from "./Technologies";
import Introduction from './Introduction'
import Pricing from "./Pricing";
import AboutUs from "./AboutUs";
import TransitionSection from "./TransitionSection";
import FooterModern from "./FooterModern";


const Overlay = forwardRef(({ caption, scroll }, ref) => (
  <div
    ref={ref}
    onScroll={(e) => {
      scroll.current = e.target.scrollTop / (e.target.scrollHeight - window.innerHeight)
    }}
    className="scroll"
    id="containerElement"
    >     
      
    <Header/>
    <Element name="landingPage">
      <LandingPage/>
    </Element>  
    <SpaceMedium/>   
    <AboutUs/>
    <SpaceMedium/>  
    <Element name="references">
      <References/>
    </Element>
    <SpaceMedium/>  
    <Element name="aboutUs">
      <Introduction/>
    </Element>
    <ContactButton/>
    <SpaceMedium/>  
    <TransitionSection/>
    <Technologies/>
    {/* <Pricing/> */}
    <FooterModern/>   
  </div>
))

export default Overlay
